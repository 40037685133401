import React, { useEffect, useState } from "react";
import { methodPaymentAvailable } from "../../services/methodsPayamentAvailable";
import { useSelector } from "react-redux";
import { formatPrice } from "../../utils/formatPrice";
import Swal from "sweetalert2";
import ReservationModal from "../ReservationModal/ReservationModal";
import { CheckBoxCustom } from "../CheckBoxCustom/CheckBoxCustom";
import regex from "../../utils/regex";

import "./styles.css";

export const MethodPayment = () => {
  const linkMethodPayment = process.env.REACT_APP_IMAGE_METHOD_PAYMENT;
  const userCounter = useSelector((state) => state.passengers);
  const itemRedux = useSelector((state) => state.itemProduct);
  const totalFormsPassengers = useSelector(
    (state) => state.totalFormsPassengers
  );
  // console.log("estoy en el metodo de pago", itemRedux);
  const [dataPayment, setDataPayment] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [transactionCost, setTransactionCost] = useState(0);
  const [show, setShowModal] = useState(false);

  const [terms, setTerms ] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    methodsPayemnts();
  }, []);

  useEffect(() => {
    if (selectedMethod) {
      setTransactionCost(selectedMethod.value);
    }
  }, [selectedMethod]);

  const methodsPayemnts = async () => {
    methodPaymentAvailable().then((response) => {
      setDataPayment(response);
    });
  };

  const handleChange = (event) => {
    const selectedMethodCode = event.target.value;
    const method = dataPayment.find(
      (method) => method.cod === parseInt(selectedMethodCode)
    );
    setSelectedMethod(method);
  };

  const handleTermsChange = (event) => {
    // console.log("terms", terms);
    setTerms(event.target.checked);
  };

  const handleReservation = () => {
    const errors = [];

    totalFormsPassengers.forEach((form, index) => {
      const missingFields = {};

      if (!form.names) {
        missingFields.names = "El nombre es requerido";
      }

      if (form.names.length < 3) {
        missingFields.names = "El nombre debe tener al menos 3 caracteres";
      }

      if (!regex.regexName.test(form.names)) {
        missingFields.names =
          "El nombre no puede contener caracteres especiales";
      }

      if (!form.lastnames) {
        missingFields.lastnames = "Los apellidos son requeridos";
      }

      if (form.lastnames.length < 3) {
        missingFields.lastnames =
          "Los apellidos deben tener al menos 3 caracteres";
      }

      if (!regex.regexName.test(form.lastnames)) {
        missingFields.lastnames =
          "Los apellidos no pueden contener caracteres especiales";
      }

      if (form.selectId < 0) {
        missingFields.selectId = "Seleccione un tipo de documento";
      }

      if (!form.numberId) {
        missingFields.numberId = "El número de documento es requerido";
      }

      if (!regex.regexIdentification.test(form.numberId)) {
        missingFields.numberId = "El número de documento es inválido";
      }

      if (!form.email) {
        missingFields.email = "El correo es requerido";
      } else if (!regex.regexEmail.test(form.email)) {
        missingFields.email = "Correo inválido";
      }

      if (form.selectOrigen < 0) {
        missingFields.selectOrigen = "Seleccione un lugar de recogida";
      }

      if (!form.cel) {
        missingFields.cel = "El número de celular es requerido";
      }

      if (!regex.regexCelphone.test(form.cel)) {
        missingFields.cel = "El número de celular es inválido";
      }

      if (!form.phone) {
        missingFields.phone = "El número de teléfono es requerido";
      }

      if (!regex.regexPhone.test(form.phone)) {
        missingFields.phone = "El número de teléfono es inválido";
      }

      if (Object.keys(missingFields).length > 0) {
        errors.push({ index, missingFields });
      }

    });

    if (!terms) {
      Swal.fire({
        icon: "error",
        title: "Por favor acepte los términos y condiciones",
        html: `<div style="text-align: justify;">Debe aceptar los términos y condiciones para continuar.</div>`,
      });
      return;
    }

 

    if (errors.length > 0) {
      let errorMessage = ``;
      errors.forEach((error) => {
        const index = error.index;
        const missingFields = error.missingFields;

        errorMessage += `<b>Faltan campos por llenar en la persona #${
          index + 1
        }:</b> \n`;

        Object.keys(missingFields).forEach((field) => {
          errorMessage += `- ${missingFields[field]}\n`;
        });
      });

      Swal.fire({
        icon: "error",
        title: "Por favor complete los campos requeridos",
        html: `<div style="text-align: justify;">${errorMessage.replace(
          /\n/g,
          "<br>"
        )}</div>`,
      });
    } else {
      handleShow();
    }
  };

  return (
    <>
      <div className="container_MethodPayment">
        <div className="MethodPayment_item">
          <div className="text_img">
            <h1>Método de pago</h1>
          </div>

          <select
            name="metodoPago"
            id="metodoPago"
            className="input_MethodPayment"
            onChange={handleChange}
            value={selectedMethod ? selectedMethod.cod.toString() : ""}
            placeholder="Seleccione un metodo de pago"
          >
            <option value="">Seleccione</option>
            <option disabled style={{ color: "gray" }}>
              ---Opciones de pago---
            </option>
            {dataPayment.map((method) => (
              <option key={method.cod} value={method.cod}>
                {method.name}
              </option>
            ))}
            <h1 style={{ zIndex: "222" }}>hola</h1>
          </select>
          {selectedMethod ? (
            <img
              src={`${linkMethodPayment}${selectedMethod.cod}.png`}
              alt="metodo de pago"
              style={{
                width: "7rem",
                height: "3.5rem",
              }}
            />
          ) : null}
        </div>

        <div className="methodPaymentTotal">
          <div>
            {userCounter.adults > 0 ? (
              <h5>
                Cantidad Transporte Adulto(S)...... # {userCounter.adults}
              </h5>
            ) : null}
            {userCounter.children > 0 ? (
              <h5>
                Cantidad Transporte Niño(S)...... # {userCounter.children}
              </h5>
            ) : null}
            <h5>Costo Transacción... </h5>
            <h5>Total... </h5>
          </div>

          <div className="costs_pay">
            {userCounter.adults > 0 ? (
              <h5>
                {" "}
                {itemRedux.priceDcto != null
                  ? formatPrice(itemRedux.priceDcto * userCounter.adults)
                  : formatPrice(itemRedux.price * userCounter.adults)}{" "}
              </h5>
            ) : null}

            {userCounter.children > 0 &&
            itemRedux.typename == "ProgrammingPopulateResponse" ? (
              <h5>
                {itemRedux.priceDcto != null
                  ? formatPrice(itemRedux.priceDcto * userCounter.children)
                  : formatPrice(itemRedux.price * userCounter.children)}
              </h5>
            ) : null}

            {userCounter.children > 0 &&
            itemRedux.typename == "TourismPopulateResponseView" ? (
              <h5>
                {formatPrice(userCounter.children * itemRedux.priceChildren)}
              </h5>
            ) : null}

            <h5> {formatPrice(transactionCost)}</h5>
            <h5>
              {formatPrice(
                transactionCost +
                  userCounter.children *
                    (itemRedux.priceChildren != "No existe"
                      ? itemRedux.priceChildren
                      : itemRedux.priceDcto != null ||
                        itemRedux.priceDcto != undefined
                      ? userCounter.children * itemRedux.priceDcto
                      : userCounter.children * itemRedux.price) +
                  (itemRedux.priceDcto != null
                    ? userCounter.adults * itemRedux.priceDcto
                    : userCounter.adults * itemRedux.price)
              )}
            </h5>
          </div>
        </div>
        <div className="container_button_payment">
        <CheckBoxCustom checked={terms} onChange={handleTermsChange} />
          <button
            className="button_reservationpay"
            onClick={() => {
              // console.log(
              //   "se presiono el boton reservar",
              //   // selectedMethod,
              //   itemRedux,
              //   userCounter.children,
              //   itemRedux.priceChildren,
              //   itemRedux.priceDcto,
              //   itemRedux.priceChildren != "No existe"
              //     ? itemRedux.priceChildren
              //     : itemRedux.priceDcto != null ||
              //       itemRedux.priceDcto != undefined
              //     ? userCounter.adults * itemRedux.priceDcto
              //     : userCounter.adults * itemRedux.price
              // );

              selectedMethod === null || selectedMethod === undefined
                ? Swal.fire({
                    icon: "error",
                    title: "Por favor seleccione un metodo de pago",
                    html: `<div style="text-align: justify;">Por favor seleccione un metodo de pago</div>`,
                  })
                : handleReservation();
            }}
          >
            Reservar
          </button>
        </div>
      </div>

      {show && (
        <ReservationModal
          showModal={handleShow}
          closeModal={handleClose}
          selectedCodeMethodPay={selectedMethod.cod}
          selectedCostMethodPay={
            itemRedux.price == 0 || itemRedux.price == null || itemRedux.price == undefined ? 0 : selectedMethod.value

          }
        />
      )}
    </>
  );
};
