export const formatPrice = (price) => {
    if (price === undefined || price === null || isNaN(price)) {
      return '0';
    }
    
    if (price === 0) {
      return '0'; 
    }
    
    return '$ ' + price.toLocaleString('es-CO');
  };
  