import { CardCustom } from "../CardCustom/CardCustom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { formatPrice } from "../../../utils/formatPrice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getItemProduct } from "../../../redux/ItemSlice";
import { LottieAnimation } from "../../../components/LottieAnimation/LottieAnimation";

const getNumberPerPage = () => {
  if (window.innerWidth < 768) return 1;
  else if (window.innerWidth < 1024) return 2;
  else if (window.innerWidth < 1240) return 3;
  else return 4;
};

export const Carrusel = ({ data, title }) => {
  const [numberPerPage, setNumberPerPage] = useState(getNumberPerPage());
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("isLoading en carrosel", isLoading);

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    if (isLoading) {
      document.body.classList.add("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isLoading]);

  useEffect(() => {
    const handleResize = () => {
      setNumberPerPage(getNumberPerPage());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  if (!data) return <h2> No hay registros</h2>;

  return (
    <div className="itemsCard">
      {isLoading && <LottieAnimation />}
      <p className="titlefilter">{title}</p>
      <Splide
        className="splide_recommended"
        options={{
          perPage: numberPerPage,
          perMove: 2,
          pagination: false,
          autoplay: true,
          interval: 2500,
          preloadPages: 2,
          rewind: true,
          direction: "rtl",
        }}
      >
        {data.length > 0 ? (
          data.map((card, index) => (
            <SplideSlide className="splideSlide_recommended" key={index}>
              <CardCustom
                name={
                  card.nombrepaq
                    ? card.nombrepaq
                    : `${card.tour?.origin?.name} - ${card.tour?.destination?.name}`
                }
                avaliable={card.available ? card.available : card.disponibles}
                day={card.dias ? card.dias : card.tour.dias}
                night={card.night ? card.night : null}
                img={card.imagen || card.images || `logo_negro.png`}
                end={card.end ? card.end : null}
                start={card.start ? card.start : null}
                pricedcto={
                  card.precioDcto
                    ? formatPrice(card.precioDcto)
                    : card.pricedcto
                    ? formatPrice(card.pricedcto)
                    : null
                }
                price={
                  card.precio
                    ? formatPrice(card.precio)
                    : formatPrice(card.price)
                }
                dayTurims={card.dias ? card.dias : null}
                nightTurims={card.noches ? card.noches : null}
                data={card}
                handleSubmit={() => {
                  console.log("card", card);
                  dispatch(
                    getItemProduct({
                      id: card.id ? card.id : card._id,
                      name: card.tour?.origin?.name
                        ? card.tour.origin.name
                        : card.nombrepaq,
                      day: card.day ? card.day : card.dias,
                      night: card.night ? card.night : card.noches,
                      description: card.descripcion
                        ? card.descripcion
                        : "No contiene una descripción",
                      imagen: card.banner ? card.banner : card.images,
                      destination: card.tour?.destination?.name
                        ? card.tour.destination.name
                        : card.destino.name,
                      typename: card.__typename ? card.__typename : "",
                      acomodacion: card.acomodacion
                        ? card.acomodacion
                        : "No existe",
                      origen: card.tour?.origin?.name
                        ? [card.tour.origin.name]
                        : card.origen.map((item) => item.name),
                      tiquetes: card.tiquetes,
                      hospedaje: card.hospedaje,
                      traslado: card.traslado,
                      entradas: card.entradas,
                      alimentacion: card.alimentacion,
                      price: card.price ? card.price : card.precio,
                      priceChildren: card.precioNino
                        ? card.precioNino
                        : "No existe",
                      priceDcto: card.pricedcto
                        ? card.pricedcto
                        : card.precioDcto,
                      gallery: card.gallery ? card.gallery : card.galeria,
                      typeTransport: card.tipo ? card.tipo : "No existe",
                      available: card.disponibles
                        ? card.disponibles
                        : card.available,
                      startProgram: card.ida ? card.ida : card.start,
                      endProgram: card.vuelta ? card.vuelta : card.end,
                      paqueteDiario: card.paqueteDiario
                        ? card.paqueteDiario
                        : false,
                      cuposPorDia: card.cuposPorDia ? card.cuposPorDia : null,
                    })
                  );

                  setIsLoading(true);
                  navigate(`/reservar`);
                  window.scrollTo(0, 0);
                  console.log("card", card);
                  
                }}
              />
            </SplideSlide>
          ))
        ) : (
          <div className="carrusel_no_data">
            <h1>No existen programaciones por el momento</h1>
          </div>
        )}
      </Splide>
    </div>
  );
};
