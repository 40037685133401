import "./styles.css";

import appleStoreLogo from "../../../imgs/appstore.jpg";
import googlePlayLogo from "../../../imgs/playstore.jpg";

import imagen from "../../../imgs/app-group.png";

import { useSpring, animated } from "react-spring";

import Swal from "sweetalert2";

import React from "react";

export const YouTavel = () => {
  const { y } = useSpring({
    from: { y: 0 },
    to: { y: 40 },
    loop: { reverse: true },
    config: { duration: 1000 },
  });
  return (
    <div className="containerYouTravel">
      <div className="tittle-travel">
        <h1>Tu Viaje App</h1>
        <p>
          Conoce todos nuestros beneficios y transforma la manera de llegar a
          tus destinos favoritos.
        </p>
      </div>

      <div className="image-travel-container">
        <animated.div
          className="image-travel"
          style={{ transform: y.to((value) => `translateY(${value}px)`) }}
        >
          <img src={imagen} className="youTravel-image" />
        </animated.div>
      </div>

      <div className="bottons-travel">
        <div className="bottons-W">
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              Swal.fire({
                icon: "info",
                title: "¡Lo sentimos!",
                text: "Esta función estará disponible muy pronto",
                timer: 1500,
                showConfirmButton: false,
              })
            }
          >
            <img src={appleStoreLogo} alt="Descargar en App Store" />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=com.apptuviaje&hl=es_419"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googlePlayLogo} alt="Descargar en Google Play" />
          </a>
        </div>
      </div>
    </div>
  );
};
