import React from "react";
import { CustomCardCoordinator } from "../CustomCardCoordinator/CustomCardCoordinator";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getItemProduct } from "../../../redux/ItemSlice";
import "./styles.css";

export const CarruselCustom = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const numRows = Math.ceil(data.length);

  // const containerHeight = numRows * 200;
  return (
    <div className="container_carroselCustom">
      {data.length >= 1 ? (
        data.map((card, index) => (
          <React.Fragment key={index}>
            <CustomCardCoordinator
              name={
                card.tour?.origin?.name
                  ? card.tour.origin.name
                  : card.nombrepaq?.substring(0, 25)
              }
              day={card.day ? card.day : card.dias}
              night={card.night ? card.night : card.noches}
              description={
                card.descripcion
                  ? card.descripcion.substring(0, 95)
                  : "No contiene una descripción"
              }
              price ={ card.__typename == "TourismPopulateResponseView" ? 
                card.precioDcto == null ? card.precio : card.precioDcto : card.pricedcto == null ? card.price : card.pricedcto}
              img={card.imagen ? card.imagen : card.images}
              ubi={
                card.tour?.destination?.name
                  ? card.tour.destination.name.substring(0, 9)
                  : card.destino.name.substring(0, 10)
              }
              typename={card.__typename ? card.__typename : ""}
              start={card.ida ? card.ida : card.start}
              end={card.vuelta ? card.vuelta : card.end}
              onClick={() => {
                dispatch(
                  getItemProduct({
                    id: card.id ? card.id : card._id,
                    name: card.tour?.origin?.name
                      ? card.tour.origin.name
                      : card.nombrepaq,
                    day: card.day ? card.day : card.dias,
                    night: card.night ? card.night : card.noches,
                    description: card.descripcion
                      ? card.descripcion
                      : "No contiene una descripción",
                    imagen: card.banner ? card.banner : card.images,
                    destination: card.tour?.destination?.name
                      ? card.tour.destination.name
                      : card.destino.name,
                    typename: card.__typename ? card.__typename : "",
                    acomodacion: card.acomodacion
                      ? card.acomodacion
                      : "No existe",
                    origen: card.tour?.origin?.name
                      ? [card.tour.origin.name]
                      : card.origen.map((item) => item.name),
                    tiquetes: card.tiquetes,
                    hospedaje: card.hospedaje,
                    traslado: card.traslado,
                    entradas: card.entradas,
                    alimentacion: card.alimentacion,
                    price: card.price ? card.price : card.precio,
                    priceChildren: card.precioNino
                      ? card.precioNino
                      : "No existe",
                    priceDcto: card.pricedcto
                      ? card.pricedcto
                      : card.precioDcto,
                    gallery: card.gallery ? card.gallery : card.galeria,
                    typeTransport: card.tipo ? card.tipo : "No existe",
                    available: card.disponibles
                      ? card.disponibles
                      : card.available,
                    startProgram: card.ida ? card.ida : card.start,
                    endProgram: card.vuelta ? card.vuelta : card.end,
                    paqueteDiario: card.paqueteDiario
                      ? card.paqueteDiario
                      : false,
                    cuposPorDia: card.cuposPorDia ? card.cuposPorDia : null,
                  })
                );
                navigate(`/reservar`);
                window.scrollTo(0, 0);
              }}
            />
          </React.Fragment>
        ))
      ) : (
        <div>
          <h1>No hay registros</h1>
        </div>
      )}
    </div>
  );
};
